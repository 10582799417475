import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import RequestQuote from "../../components/RequestQuote"
import FacebookIcon from "../../components/Icons/FacebookIcon"
import InstagramIcon from "../../components/Icons/InstagramIcon"
import MapPointerIcon from "../../components/Icons/MapPointerIcon"
import PaperPlanIcon from "../../components/Icons/PaperPlanIcon"
import PhoneLineIcon from "../../components/Icons/PhoneLineIcon"
import BreakpointDown from "../../components/Media/BreakpointDown"
import {Container} from "../../components/section"

const FooterWrapper = styled.footer`
  margin:0;
  padding:30px 0 0;
  background-color:#fff;
  border-top:1px solid #ddd;  
`
const AddressList = styled.div`
  color:#020304;	
`
const AddressItem = styled.div`
  display:flex;
  flex-wrap:nowrap;
  padding:5px 0;
  .icon{
    display:flex;
    align-items:center;
    height:26px;
  }  
  .text{
    display:inline-flex;
    font-size:16px;
    line-height:26px;
  }  
  a.text{
    color:#020304;
    &:hover{
      color:#020304;
    }
  }
  .icon + .text{margin-left:10px;}  
`
const MenuGrid = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  margin:0 -5px;
`
const MenuItemGrid = styled.div`
  position:relative;
  width:100%;
  padding:0 5px;

  @media(min-width:576px){
    flex:0 0 50%;
    max-width: 50%;
  }
  @media(min-width:768px){
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  &:last-child{
    flex:0 0 100%;
    max-width: 100%;
    @media(min-width:768px){
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
  }  
  @media(max-width:575.98px){
    + .menu-item-grid{
      padding-top:15px;
      border-top:1px solid #ddd;
    }
  }
`
const MenuTitle = styled.h3`
  font-size:20px;
  line-height:24px;
  font-weight:700;
  color:#444;
  font-family: 'Roboto', serif;
  margin-bottom:10px;
  @media(min-width:768px){
    margin-bottom:15px;
  }
`
const MenuList = styled.ul`
  list-style:none;
  margin:0 0 10px;
  padding:0;
  
`
const MenuItem = styled.li`
  margin:0;
  padding:0;
 
	& a {
    display:inline-flex;
    padding:5px 0;
		color: rgba(102, 102, 102, 0.7);
		&:hover {
			color:#020304;
		}
	}
`
const FooterTop = styled.div`
  padding:0;
  @media(min-width:768px){
    padding:30px 0;
  }
`
const FooterMiddle = styled.div`
  padding:20px 0;
 @media(min-width:768px){
  padding:30px 0;
 }
`
const FooterBottom = styled.div`
  color:rgba(102,102,102,0.7);
  border-top:1px solid #ddd;
  padding:20px 0 60px 0;
  @media(min-width:768px){
    padding:30px 0;
  }
`
const CopyrightGrid = styled.div`
  display:flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: center;
  @media(min-width:576px){
    justify-content: space-between;
  }
  
`
const Copyright = styled.div`
  font-size:12px;
  line-height:18px;
  margin-bottom:5px;
  text-align:center;
  @media(min-width:576px){
    font-size:14px;
    line-height:20px;
    margin-bottom:0;
    text-align:left;
  }
`
const SocialIcon = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;  
  ${BreakpointDown.sm`    
    justify-content: inherit;
  `}
  
`
const SocialItem = styled.li`
  list-style: none;    
  margin:0 10px;
  a{
    width: 40px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #DEDEDE;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      fill:#9B9EA2;
    }
    &:hover{
      border-color:#020304;
      svg{
        fill:#020304;
      }
    }
  }
`

const Footer = ({ location, noQuote = false }) => (
  <FooterWrapper>
    {!noQuote && <FooterTop>
      <Container>
        <RequestQuote location={location} />
      </Container>
    </FooterTop>}
    <FooterMiddle>
      <Container>
        <MenuGrid className="menu-grid">
          <MenuItemGrid className="menu-item-grid">          
            <MenuTitle>Our Buildings</MenuTitle>
            <MenuList>
              <MenuItem><Link to='/commercial-metal-buildings'>Commercial Buildings</Link></MenuItem>
              <MenuItem><Link to='/metal-garages'>Garages</Link></MenuItem>
              <MenuItem><Link to='/metal-barns'>Barns</Link></MenuItem>
              <MenuItem><Link to='/metal-rv-covers'>RV Covers</Link></MenuItem>
            </MenuList>
          </MenuItemGrid>
          <MenuItemGrid className="menu-item-grid">          
            <MenuTitle>Important Links</MenuTitle>
            <MenuList>
              <MenuItem><Link to="/about-us">About Premier</Link></MenuItem>
              <MenuItem><Link to='/rto'>Financing Options</Link></MenuItem>
              <MenuItem><Link to='/faq'>Frequently Asked Questions</Link></MenuItem>
              <MenuItem><Link to='/blog'>Blog</Link></MenuItem>
              <MenuItem><Link to='/contact-us'>Contact us</Link></MenuItem>
              <MenuItem><Link to='/specials'>Specials</Link></MenuItem>
            </MenuList>
          </MenuItemGrid>
          <MenuItemGrid className="menu-item-grid">          
            <MenuTitle>Buying Guide</MenuTitle>
            <MenuList>
              <MenuItem><Link to="/metal-building-roof-styles">Metal Building Roof Guide</Link></MenuItem>
              <MenuItem><Link to="/site-preparation">Site Preparation Guide</Link></MenuItem>
              <MenuItem><Link to="/order-process">Order/Installation Process</Link></MenuItem>
            </MenuList>
          </MenuItemGrid>
          <MenuItemGrid className="menu-item-grid">          
            <MenuTitle>Other Links</MenuTitle>
            <MenuList>
              <MenuItem><Link to="/privacy-policy">Privacy Policy</Link></MenuItem>
              <MenuItem><Link to="/terms-of-use">Terms of Use</Link></MenuItem>
              <MenuItem><Link to="/sitemap">Sitemap</Link></MenuItem>
            </MenuList>
          </MenuItemGrid>
          <MenuItemGrid className="menu-item-grid">
            <MenuTitle>Our Address</MenuTitle>
            <AddressList>
              <AddressItem>
                <span className="icon"><MapPointerIcon fill="#666" /></span>
                <span className="text">1124 North South Street Mount Airy North Carolina 27030</span>
              </AddressItem>
              <AddressItem>
                  <span className="icon"><PhoneLineIcon fill="#666" /></span>
                  <a href="tel:8776005980" className="text">(877) 600-5980</a>
              </AddressItem>
              <AddressItem>
                <span className="icon"><PaperPlanIcon fill="#666" /></span>
                <a href="mailto:sales@premiermetalstructures.com" className="text">sales@premiermetalstructures.com</a>
              </AddressItem>
            </AddressList>
          </MenuItemGrid>
        </MenuGrid>
      </Container>
    </FooterMiddle>
    <FooterBottom>
      <Container>
        <CopyrightGrid>
          <Copyright>© {new Date().getFullYear()} Premier Metal Structures. All rights reserved.</Copyright>
          <SocialIcon>
            <SocialItem>
              <Link className="item-link" to="https://www.facebook.com/premiermetalstructures/"><FacebookIcon /></Link>
            </SocialItem>
            <SocialItem>
              <Link className="item-link" to="https://www.instagram.com/premiermetalstructures/"><InstagramIcon /></Link>
            </SocialItem>
          </SocialIcon>
        </CopyrightGrid>
      </Container>
    </FooterBottom>
  </FooterWrapper>
)

export default Footer
