import styled from "styled-components"
import BreakpointUp from "../Media/BreakpointUp"

export const Form = styled.div`
  max-width: ${props => props.maxWidth};
  width: 100%;
  margin: 0 auto;
`
Form.defaultProps = {
  maxWidth: "100%",
}

export const FormTitle = styled.div`
  margin-bottom: ${props => props.mb};
  font-weight: 700;
  color: ${props => props.color};

  text-align: center;

  font-size: 18px;
  line-height: 30px;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (min-width: 1200px) {
    font-size: 30px;
    line-height: 40px;
  }
`
FormTitle.defaultProps = {
  mb: "40px",
  color: "#373636",
}

export const FormSubTitle = styled.div`
  text-align: center;
  color: ${props => props.color};
  margin-bottom: ${props => props.mb};
  font-size: 14px;
  line-height: 24px;
  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 28px;
  }
  @media (min-width: 992px) {
    font-size: 18px;
    line-height: 30px;
  }
  @media (min-width: 1200px) {
    font-size: 22px;
    line-height: 32px;
  }
`
FormSubTitle.defaultProps = {
  mb: "15px",
  color: "#373636",
}

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  ${BreakpointUp.xxl`
        margin:0 -15px;
    `}
`
export const FormTwoCol = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  ${BreakpointUp.sm` 
        flex: 0 0 50%;
        max-width: 50%;
    `}
  ${BreakpointUp.xxl`
        padding:0 15px;
    `}
`
export const FormCol = styled.div`
  position: relative;
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 10px;
  ${BreakpointUp.xxl`
        padding:0 15px;
    `}
`
export const FormAction = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => props.justifyContent};
  .btn {
    text-transform: capitalize;
  }
`
