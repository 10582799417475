import React from "react"
import styled from "styled-components"
import Img from 'gatsby-image'
import { Link } from "gatsby"
import { v4 } from "uuid"
import { Section, SectionTitle } from "../../components/section"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import Logo  from "../../images/logo.svg"
import BreakpointUp from "../../components/Media/BreakpointUp"
import BreakpointDown from "../../components/Media/BreakpointDown"
import QuotePopup from '../../components/QuotePopup'

import generateHTML from '../../utils/generateHTML';
import Content, { HTMLContent } from '../../components/Content';
import QuotePopupModal from "../../components/QuotePopupModal"

const SectionSubtitle = styled.div`	
	font-family: 'IBM Plex Serif', serif;
    font-weight: 700;
    color:#020304;
	text-align: center;
	font-size: 28px;
	line-height: 38px;
	${BreakpointUp.md`
		font-size: 38px;
		line-height: 48px;
	`}
	
`
const BackgroundImageWrap = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
`
const BackgroundImage = styled.div`
	background-image:${props => props.bgImg};
	background-repeat: no-repeat;
	background-position: center center;
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0px;
	left: 0;
	background-size: cover;
	&:after{
		content:'';
		position:absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		background: rgba(246, 246, 246, 0.3);
	}
`
const ContentGrid = styled.div`
	margin-right: -15px;
	margin-left: -15px;		
	${BreakpointUp.lg`		
		display: flex;
		flex-wrap: wrap;
		margin:0 -60px;
	`}
`
const ContentLeft = styled.div`
	position:relative;		
	padding:15px;
	${BreakpointUp.lg`	
		flex: 0 0 50%;
		max-width: 50%;
		padding:0 60px;
	`}
	/* @media (min-width:1600px) {
		padding:0 90px;
	} */
`
const ContentRight = styled.div`
	position:relative;
	padding:15px;	
	${BreakpointUp.lg`				
		flex: 0 0 50%;
		max-width: 50%;
		padding:0 60px;
		&:before{
			content: '';
			display: block;
			height: 100%;
			width: 1px;
			background-color: rgba(2, 3, 4, 0.15);
			position: absolute;
			left: -1px;
			top: 0;	
		}		
	`}
	/* @media (min-width:1600px) {
		padding:0 90px;
	} */
`
const ProductReview = styled.div`
	border-top:1px solid rgba(2, 3, 4, 0.15);		
	border-bottom:1px solid rgba(2, 3, 4, 0.15);
	padding: 30px 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	width: 100%;
	${BreakpointUp.lg`				
		max-width: 544px;			
	`}
`
const ProductReviewItem = styled.div`
	padding:5px 0;
	text-align:center;
	& .number{
		display:block;
		font-size:32px;
		font-family: 'IBM Plex Serif', serif;
		color:#020304;
		font-weight:700;
		margin-bottom: 5px;
		font-weight: 700;
		line-height: 34px;
	}
	& .text{
		color:#666;
		font-weight:500;
		${BreakpointDown.xl`				
			max-width: 115px;
			width: 100%;
			display: block;
			line-height: 20px;
		`}
	}
`
// const NavBrandWhite = styled.div`
// 	width:220px;
// 	height:160px;		
// 	background-color:#fff;
// 	padding:30px 20px  20px 20px;
// 	position:absolute;
// 	left:50%;
// 	transform:translateX(-50%);
// 	top:0;
// 	z-index:1;
// 	text-align: center;
// 	box-shadow: 0px 10px 45px rgba(0,0,0,0.1);
// `
const ProductService = styled.div`
	width: 100%;		
	margin:0 auto;
`
const ProductServiceItem = styled.div`
	display:block;
	border-bottom:1px solid rgba(2, 3, 4, 0.15);		
	margin-bottom: 20px;
	padding: 0 0 0 90px;
	position: relative;
	& .icon{		
		width:74px;
		height:64px;
		background-color:#fff;	
		position: absolute;
		top:0;
		left:0;
		display: flex;
		align-items: center;
		justify-content: center;
		svg{
			fill:#666;
		}
	}
	& .text{
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 64px;
		& h3{
			color:#020304;
			font-weight:700;
			font-size:20px;
			font-family: 'Roboto', serif;
			margin:0;
		}
	}
`
const ReadMore = styled(Link)`  
  display: none;
  	${BreakpointUp.lg`
		display:block;	
		position:absolute;
		left:${props => props.left};
		right:${props => props.right};
		top:50%;
		transform: rotate(-90deg)translateY(-50%);
		z-index: 1;
		color:#020304;
		font-weight:700;
		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			display: block;
			height: 2px;
			transition: -webkit-transform .4s cubic-bezier(.77,0,.175,1);        
			transition: transform .4s cubic-bezier(.77,0,.175,1);
			transition: transform .4s cubic-bezier(.77,0,.175,1),-webkit-transform .4s cubic-bezier(.77,0,.175,1);        
			transform: translateZ(0) scaleX(1);
			background: #020304;
			transform-origin: right center;    
			transform: translateZ(0) scaleX(0);        
		}
		& .text{
			+ & svg{
			margin-left:5px;
			}
		}
		& svg{
			fill:#020304;
			transition: transform 0.3s ease-out;
		}
		&:hover{
			color:#020304;
			&:after {
				transform-origin: left center;        
				transform: translateZ(0) scaleX(1);
			}  
			svg{
				fill:#020304;
				transform: translateX(4px);
			}			
		}						
			
	`}
`
const ReadMoreButton = styled.div`  
  display: none;
  	${BreakpointUp.lg`
		display:block;	
		position:absolute;
		left:${props => props.left};
		right:${props => props.right};
		top:50%;
		transform: rotate(-90deg)translateY(-50%);
		z-index: 1;
		color:#020304;
		font-weight:700;
		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			display: block;
			height: 2px;
			transition: -webkit-transform .4s cubic-bezier(.77,0,.175,1);        
			transition: transform .4s cubic-bezier(.77,0,.175,1);
			transition: transform .4s cubic-bezier(.77,0,.175,1),-webkit-transform .4s cubic-bezier(.77,0,.175,1);        
			transform: translateZ(0) scaleX(1);
			background: #020304;
			transform-origin: right center;    
			transform: translateZ(0) scaleX(0);        
		}
		& .text{
			+ & svg{
			margin-left:5px;
			}
		}
		& svg{
			fill:#020304;
			transition: transform 0.3s ease-out;
		}
		&:hover{
			cursor: pointer;
			color:#020304;
			&:after {
				transform-origin: left center;        
				transform: translateZ(0) scaleX(1);
			}  
			svg{
				fill:#020304;
				transform: translateX(4px);
			}			
		}						
			
	`}
`
class IntroSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isVisibleQuote: false,
		}
		this.showQuote = this.showQuote.bind(this)
    this.onClosePopup = this.onClosePopup.bind(this)
	}
	showQuote() {
    this.setState({
      isVisibleQuote: true,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.add('modal-open')
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.remove('modal-open')
    }
  }
	render() {
		const { sectionData, location } = this.props
		const { isVisibleQuote } = this.state
		const PostContent = HTMLContent || Content;
		return(
			<>
			<Section pt="90px" pb="90px" xpt="60px" xpb="60px" >
				{sectionData[0].sectionImages[0].fluid?(
					<BackgroundImageWrap>
						<Img fluid={sectionData[0].sectionImages[0].fluid} />
					</BackgroundImageWrap>
				):(
					<BackgroundImage bgImg={`url(${sectionData[0].sectionImages[0].file.url})`} />       
				)}
				{/* <NavBrandWhite>
					<img src={Logo} alt="logo" />
				</NavBrandWhite> */}
				<ReadMore to={sectionData[0].sectionButtonLink} left="-70px">
					<span className="text">{sectionData[0].sectionButtonText}</span>
					<ArrowIcon/>
				</ReadMore>
				<ReadMoreButton right="-70px" onClick={this.showQuote}>
					<span className="text">Request a Quote Now</span>
					<ArrowIcon/>
				</ReadMoreButton>
				<div className="container">
					<ContentGrid>	 	
						<ContentLeft>
							<SectionSubtitle>{sectionData[0].subTitle}</SectionSubtitle>
							<SectionTitle mb="30px">{sectionData[0].sectionTitle}</SectionTitle>
		  				<PostContent
	              content={generateHTML(sectionData[0].sectionDescription.childMarkdownRemark.rawMarkdownBody)}
		          />
							<ProductReview>
								{
									sectionData[0].features.map((item, i)=> {
										return(
											<ProductReviewItem key={v4()}>
												<span className="number">{item.feature}</span>
												<span className="text">{item.title}</span>
											</ProductReviewItem>
										)
									})
								}
							</ProductReview>
						</ContentLeft>					
						<ContentRight>
							<SectionSubtitle>{sectionData[1].subTitle}</SectionSubtitle>
							<SectionTitle mb="30px">{sectionData[1].sectionTitle}</SectionTitle>
							<ProductService>
								{
									sectionData[1].sectionImages.map((item, i)=>{
										return(
											<ProductServiceItem key={v4()}>
												<div className="icon">
													<img src={item.file.url} alt={item.title} />
												</div>
												<div className="text">
													<h3>{item.title}</h3>									
												</div>
											</ProductServiceItem>
										)
									})
								}
							</ProductService>
						</ContentRight>
					</ContentGrid>
				</div>
				
			</Section>
			<QuotePopupModal isVisible={isVisibleQuote} onClose={this.onClosePopup} location={location} />
			</>
		)
	}
}

export default IntroSection