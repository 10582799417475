import styled from "styled-components"
import BreakpointUp from "../Media/BreakpointUp"

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  display: ${props => (props.hidden ? "none" : "block")};
`

export const CloseButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  width: 38px;
  height: 38px;
  cursor: pointer;
  z-index: 1;
  background-color: #484b58;
  transition: all 0.5s ease-in-out;
  svg {
    width: 12px;
    height: 12px;
    fill: #fff;
  }
  &:hover {
    background-color: #161420;
    svg {
      fill: #fff;
    }
  }
`

export const ModalDialog = styled.div`
  position: relative;
  width: auto;
  pointer-events: none;
`

export const ModalContent = styled.div`
  background-color: ${props => props.bgColor};
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
`
ModalContent.defaultProps = {
  bgColor: "#fff",
}

export const ModalBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 40px 20px 20px;
  ${BreakpointUp.md`
		padding:60px 30px 30px;
	`}
  ${BreakpointUp.lg`
		padding: 60px;
	`}
`

export const ModalSubTitle = styled.div`
  font-weight: 500;
  color: #373636;
`

export const ModalTitle = styled.div`
  font-weight: 600;
  color: #373636;
  text-transform: uppercase;
`

export const ModalFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
`

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #161420;
  inset: 0px;
  opacity: 0.8;
  display: ${props => (props.hidden ? "none" : "block")};
`
