import React from "react"
import styled from "styled-components"
import FormButton from "../button/FormButton"
import axios from "axios"
import { navigate } from "gatsby"
import CloseIcon from "../Icons/CloseIcon"
import { FormControlLabel } from "../section"

const Wrap = styled.div`
  display: ${props => props.display};
  position: fixed;
  z-index: 1000;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);

  width: 100%;
  max-width: 600px;
  padding: 50px 82px 30px 82px;
  background: white;
  border: 1px solid #dddddd;

  & h2 {
    text-align: center;
    margin-bottom: 36px;
  }
  & .form-action {
    text-align: center;
  }
  height: 90vh;
  overflow-y: auto;
  @media (max-width: 480px) {
    top: 30px;
    padding: 50px 40px;
  }
`

const CloseButton = styled.div`
  position: absolute;
  top: 26px;
  right: 26px;
  width: 30px;
  height: 30px;

  &:hover {
    cursor: pointer;
  }
`

class QuotePopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      first_name: "",
      email: "",
      phone_no: "",
      state: "",
      zipcode: "",
      comment: "",
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      const searchParams = new URLSearchParams(window.location.search)
      if (
        searchParams.get("utm_source") != "" &&
        searchParams.get("utm_source") != null
      ) {
        sessionStorage.setItem("utm_source", searchParams.get("utm_source"))
        sessionStorage.setItem("utm_medium", searchParams.get("utm_medium"))
        sessionStorage.setItem("utm_campaign", searchParams.get("utm_campaign"))
        sessionStorage.setItem("utm_term", searchParams.get("utm_term"))
        sessionStorage.setItem("utm_content", searchParams.get("utm_content"))
      }
    }
  }

  handleChange(event) {
    const { id, value } = event.target
    this.setState({
      [id]: value,
    })
  }
  handleSubmit(event) {
    let utm_source_val = "website"
    let utm_medium_val = ""
    let utm_campaign_val = ""
    let utm_term_val = ""
    let utm_content_val = ""
    if (
      sessionStorage.getItem("utm_source") != "" &&
      sessionStorage.getItem("utm_source") != null
    ) {
      utm_source_val = sessionStorage.getItem("utm_source")
      utm_medium_val = sessionStorage.getItem("utm_medium")
      utm_campaign_val = sessionStorage.getItem("utm_campaign")
      utm_term_val = sessionStorage.getItem("utm_term")
      utm_content_val = sessionStorage.getItem("utm_content")
    }

    event.preventDefault()
    const { location } = this.props
    const currentUrl = window.location.href
    const data = {
      source: location.origin,
      form: "Quote Form",
      page: currentUrl,
      first_name: this.state.first_name,
      email: this.state.email,
      phone_no: this.state.phone_no,
      state: this.state.state,
      zipcode: this.state.zipcode,
      comment: this.state.comment,
      utm_medium: utm_medium_val,
      utm_term: utm_term_val,
      utm_campaign: utm_campaign_val,
      utm_content: utm_content_val,
      lead_source: utm_source_val,
    }
    axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/create-lead-wordpress",
      data: data,
      headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN },
    })
      .then(res => {
        document.body.classList.remove("popup-open")
        if (typeof dataLayer !== "undefined") {
          window.dataLayer.push({
            event: "form_submit",
            formName: "website_forms",
            enhanced_conversion_data: {
              email: this.state.email,
              phone_number: this.state.phone_no,
            },
          })
        }
        this.setState({
          first_name: "",
          email: "",
          phone_no: "",
          state: "",
          zipcode: "",
          comment: "",
        })
        navigate("/thanks")
      })
      .catch(error => {
        alert(error)
      })
  }
  render() {
    const { isVisible } = this.props
    return (
      <Wrap display={isVisible ? "block" : "none"}>
        <CloseButton onClick={this.props.onClose}>
          <CloseIcon />
        </CloseButton>
        <h2>Request A Quote</h2>
        <form id="quoteform" className="quoteform" onSubmit={this.handleSubmit}>
          <div className="form-group-bordered">
            <input
              type="text"
              id="first_name"
              className="form-control"
              onChange={this.handleChange}
              required
            />
            <FormControlLabel
              className={
                this.state.first_name !== ""
                  ? "control-label active"
                  : "control-label"
              }
            >
              Your Full Name
            </FormControlLabel>
          </div>
          <div className="form-group-bordered">
            <input
              type="email"
              id="email"
              className="form-control"
              onChange={this.handleChange}
              required
            />
            <FormControlLabel
              className={
                this.state.email !== ""
                  ? "control-label active"
                  : "control-label"
              }
            >
              Email Address
            </FormControlLabel>
          </div>
          <div className="form-group-bordered">
            <input
              type="tel"
              id="phone_no"
              className="form-control"
              onChange={this.handleChange}
              pattern="[0-9]{10}"
              required
            />
            <FormControlLabel
              className={
                this.state.phone_no !== ""
                  ? "control-label active"
                  : "control-label"
              }
            >
              Phone Number
            </FormControlLabel>
          </div>
          <div className="form-group-bordered">
            <select
              id="state"
              className="form-control"
              onChange={this.handleChange}
            >
              <option value="">State</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>
          <div className="form-group-bordered">
            <input
              id="zipcode"
              className="form-control"
              onChange={this.handleChange}
              required
            />
            <FormControlLabel
              className={
                this.state.zipcode !== ""
                  ? "control-label active"
                  : "control-label"
              }
            >
              Zip Code
            </FormControlLabel>
          </div>
          <div className="form-group-bordered">
            <textarea
              id="comment"
              className="form-control"
              onChange={this.handleChange}
              required
            />
            <FormControlLabel
              className={
                this.state.comment !== ""
                  ? "control-label active"
                  : "control-label"
              }
            >
              Write Your Comment Here…
            </FormControlLabel>
          </div>
          <div className="form-action">
            <FormButton text="Submit" />
          </div>
        </form>
      </Wrap>
    )
  }
}
export default QuotePopup
