import React from "react"
import styled from "styled-components"
import axios from "axios"
import { navigate, Link } from "gatsby"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { v4 } from "uuid"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  Section,
  SectionTitle,
  SectionDescription,
  SectionHeader,
  SectionPageTitle,
  BreadCrumb,
  MarkdownContent,
  FormControlLabel,
} from "../components/section"
import CallToAction from "../components/CallToAction"
import RelatedProductCarousel from "../components/RelatedProductCarousel"
import PhoneIcon from "../components/Icons/PhoneIcon"
import QouteIcon from "../components/Icons/QouteIcon"
import DefaultButton from "../components/button/DefaultButton"
import FormButton from "../components/button/FormButton"
import DefaultOutlineButton from "../components/button/DefaultOutlineButton"
import PaymentStep from "../components/PaymentStep"
import DoubleFrameBigBg from "../images/doubleframebig.jpg"
import BreakpointUp from "../components/Media/BreakpointUp"
import BreakpointDown from "../components/Media/BreakpointDown"
import Content, { HTMLContent } from "../components/Content"
import QuotePopup from "../components/QuotePopup"

import generateHTML from "../utils/generateHTML"
import QuotePopupModal from "../components/QuotePopupModal"

const RtoBgImage = styled.div`
  background-image: ${props => props.bgImg};
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.05);
  }
`

const RtoGrid = styled.div`
  align-items: center;
  margin: 0 -100px;
  ${BreakpointUp.lg`				
    display:flex;
    flex-wrap:wrap;
  `}
`
const TabContainer = styled.div`
  display: block;
  margin-top: 50px;
  ${BreakpointUp.sm`        
    display:flex;
  `}
`
const TabsWrap = styled.div`
  width: 200px;
  margin-right: 30px;
  @media (max-width: 576px) {
    width: 100%;
    display: flex;
    & div {
      width: 50% !important;
    }
  }
`
const TabContentWrap = styled.div`
  width: calc(100% - 230px);
  background: #eee;
  padding: 20px;
  overflow-y: scroll;
  height: 500px;
  @media (max-width: 576px) {
    width: 100%;
    margin-top: 30px;
  }
  img {
    max-width: 60%;
    width: 100%;
  }
  p {
    a {
      display: inline-block;
      text-align: center;
      padding: 12px 24px;
      font-size: 16px;
      font-weight: 700;
      background: #020304;
      border: 1px solid #020304;
      color: #fff;
      position: relative;
      vertical-align: middle;
      transform: perspective(1px) translateZ(0);
      transition-property: color;
      transition-duration: 0.3s;
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition-property: transform;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
      }
      > .text {
        line-height: 20px;
        display: inline-block;
        vertical-align: middle;
      }
      &:hover {
        border-color: #ddd;
        color: #030504;
        &:before {
          transform: scaleX(1);
        }
      }
    }
  }
`
const TabButton = styled.div`
  width: ${props => props.width};
  background: ${props => props.bg};
  border: ${props => props.border};
  box-shadow: ${props => props.boxShadow};
  opacity: ${props => props.opacity};
  padding: 20px;
  &:hover {
    cursor: pointer;
  }
`
const BenefitsColumn = styled.div`
  background-color: #f6f6f6;
  position: relative;
  padding: 60px 100px;
  ${BreakpointUp.lg`	
    padding:100px;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  `}
  & h2 {
    margin-bottom: 40px;
    text-transform: capitalize;
    font-size: 28px;
    line-height: 38px;
    ${BreakpointUp.sm`	
      font-size:34px;
      line-height:44px;
    `}
  }

  & h3 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 5px;
    font-family: "Roboto", serif;
  }
`
const BenefitsItem = styled.div`
  margin-bottom: 40px;
  ${BreakpointDown.lg`	
    position:relative;
    padding-left:110px;      
  `}
`
const CircleIcon = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  text-align: center;
  position: absolute;
  justify-content: center;
  align-items: center;
  left: 0;
  ${BreakpointUp.lg`	
      left: -45px;
    `}
  > svg {
    fill: #666;
  }
`
const BenefitsText = styled.div`
  margin: 0;
  padding: 0;
`
const FormColumn = styled.div`
  padding: 0 100px;
  position: relative;
  z-index: 1;
  ${BreakpointUp.lg`	
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    `}
  & h2 {
    margin-bottom: 40px;
    text-transform: capitalize;
    font-size: 28px;
    line-height: 38px;
    ${BreakpointUp.sm`	
        font-size:34px;
        line-height:44px;
      `}
  }
`
const FormGrid = styled.div`
  display: grid;
  align-items: center;
  grid-column-gap: 30px;
  grid-template-columns: repeat(1, 1fr);
  ${BreakpointUp.lg`                  
      grid-template-columns: repeat(2, 1fr);
    `}
`

const SectionCover = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  ${BreakpointUp.lg`              
    padding-left: calc((100% - 1380px)/2);
  `}
`
const CoverFigure = styled.div`
  padding: 0 0 0 15px;
  width: 100%;
  display: none;
  ${BreakpointUp.lg`   
    display:block;
    flex: 0 0 50%;
    max-width: 50%;
  `}
`
const CoverContent = styled.div`
  position: relative;
  width: 100%;
  padding: 140px 15px 60px 15px;
  ${BreakpointUp.lg`          
    flex: 0 0 50%;
    max-width: 50%;
  `}
  & p {
    margin-bottom: 30px;
  }
`

const ButtonToolbar = styled.div`
  margin: 0 -10px;
  & a {
    padding: 0 10px;
    ${BreakpointDown.sm`          
      display:block;
      + a{
        margin-top:20px;
      }
    `}
    & .btn {
      padding: 15px 40px;
      font-size: 20px;
      line-height: 26px;
      ${BreakpointDown.sm`     
        display:block;
      `}
    }
  }
  & .quote-btn {
    width: 100%;
    & .btn {
      padding: 16px 40px;
      font-size: 20px;
      line-height: 26px;
    }
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 580px) {
      .btn {
        width: calc(100% - 20px);
        margin: 15px 10px;
      }
    }
  }
`
const Triangle = styled.div`
  display: ${props => props.display};
  position: relative;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0px 25px 65px rgba(1, 9, 32, 0.2);
  & :after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    right: -33px;
    box-sizing: border-box;
    border: 0.5em solid;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(-135deg);
    box-shadow: -3px 3px 4px -2px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 576px) {
    display: none !important;
  }
`

class FinancePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentTab: 0,
      first_name: "",
      email: "",
      mobile_no: "",
      zipcode: "",
      state: "",
      building_type: "",
      comment: "",
      isVisibleQuote: false,
    }
    this.changeTab = this.changeTab.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.showQuote = this.showQuote.bind(this)
    this.onClosePopup = this.onClosePopup.bind(this)
  }
  handleChange(event) {
    const { id, value } = event.target
    this.setState({
      [id]: value,
    })
  }
  changeTab(data) {
    this.setState({
      currentTab: data,
    })
  }
  showQuote() {
    this.setState({
      isVisibleQuote: true,
    })
    if (typeof window !== `undefined`) {
      document.querySelector("body").classList.add("modal-open")
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    })
    if (typeof window !== `undefined`) {
      document.querySelector("body").classList.remove("modal-open")
    }
  }
  handleSubmit(event) {
    event.preventDefault()
    const { location } = this.props
    const data = {
      source: location.origin,
      form: "Financing Form",
      page: location.pathname,
      first_name: this.state.first_name,
      email: this.state.email,
      mobile_no: this.state.mobile_no,
      state: this.state.state,
      zipcode: this.state.zipcode,
      comment: this.state.comment,
    }
    axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/create-lead-wordpress",
      data: data,
      headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN },
    })
      .then(res => {
        if (typeof dataLayer !== "undefined") {
          window.dataLayer.push({
            event: "form_submit",
            formName: "website_forms",
            enhanced_conversion_data: {
              email: this.state.email,
              phone_number: this.state.mobile_no,
            },
          })
        }
        this.setState({
          first_name: "",
          email: "",
          mobile_no: "",
          state: "",
          zipcode: "",
          building_type: "",
          comment: "",
        })
        navigate("/thanks")
      })
      .catch(error => {
        alert(error)
      })
  }
  render() {
    const { data, location } = this.props
    const pageData = data.contentfulRtoFinancingPage
    const products = data.allContentfulProduct
    const PostContent = HTMLContent || Content
    const { currentTab } = this.state
    const tabsData = data.contentfulRtoFinancingPage.tabsContent
    const { isVisibleQuote } = this.state
    return (
      <Layout location={location}>
        <SEO
          title={pageData.metaTitle}
          description={pageData.metaDescription}
        />
        <SectionHeader
          pt="0"
          pb="0"
          bgColor="linear-gradient(to right,  #f6f6f6 0%,#f6f6f6 75%,#eaeaea 75%,#eaeaea 100%)"
        >
          <SectionCover>
            <CoverContent>
              <BreadCrumb>
                <Link to="/">Home</Link>/
                <span>
                  {pageData.url === "/financing" ? "Financing" : "RTO"}
                </span>
              </BreadCrumb>
              <SectionPageTitle mt="30px" mb="15px" textAlign="left">
                {pageData.title}
              </SectionPageTitle>
              <SectionDescription mb="50px" textAlign="left">
                <PostContent
                  content={generateHTML(
                    pageData.heroDescription.childMarkdownRemark.html
                  )}
                />
              </SectionDescription>
              <ButtonToolbar>
                <a href="tel:8776005980">
                  <DefaultButton
                    icon={<PhoneIcon />}
                    text="(877) 600-5980"
                    aria-label="phonenumber"
                  />
                </a>
                <span className="quote-btn" onClick={this.showQuote}>
                  <DefaultOutlineButton
                    icon={<QouteIcon />}
                    text="Request A Quote"
                  />
                </span>
              </ButtonToolbar>
            </CoverContent>
            <CoverFigure>
              <Img fluid={pageData.heroImage.fluid} />
            </CoverFigure>
          </SectionCover>
        </SectionHeader>
        {/* <QuotePopup
				  isVisible={isVisibleQuote}
					onClose={this.onClosePopup}
					location={location}
				/> */}
        <Section pt="90px" pb="90px" xpt="60px" xpb="60px">
          <div className="container">
            <SectionTitle maxWidth="550px">
              {pageData.secondSectionTitle}
            </SectionTitle>
            <TabContainer>
              <TabsWrap>
                {tabsData.map((item, i) => {
                  return (
                    <TabButton
                      width={currentTab === i ? "100%" : "95%"}
                      opacity={currentTab === i ? "1" : "0.5"}
                      bg={currentTab === i ? "#fff" : "#eee"}
                      border={currentTab === i ? "none" : "1px solid #ddd"}
                      boxShadow={
                        currentTab === i ? "0 5px 20px rgba(0,0,0, .2)" : "none"
                      }
                      onClick={() => this.changeTab(i)}
                      key={v4()}
                    >
                      {item.tabName}
                      <Triangle display={currentTab === i ? "block" : "none"} />
                    </TabButton>
                  )
                })}
              </TabsWrap>
              <TabContentWrap>
                {tabsData.map((item, i) => {
                  return (
                    <MarkdownContent
                      display={currentTab === i ? "block" : "none"}
                      key={v4()}
                    >
                      <PostContent
                        content={generateHTML(
                          item.content.childMarkdownRemark.html
                        )}
                      />
                    </MarkdownContent>
                  )
                })}
              </TabContentWrap>
            </TabContainer>
          </div>
        </Section>
        <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bdrBottom="#ddd">
          <RtoBgImage bgImg={`url(${DoubleFrameBigBg})`} />
          <div className="container">
            <RtoGrid>
              <FormColumn>
                <h2>{pageData.formTitle}</h2>
                <form id="financingform" onSubmit={this.handleSubmit}>
                  <FormGrid>
                    <div className="form-group-bordered">
                      <input
                        type="text"
                        id="first_name"
                        className="form-control"
                        onChange={this.handleChange}
                        required
                      />
                      <FormControlLabel
                        className={
                          this.state.first_name !== ""
                            ? "control-label active"
                            : "control-label"
                        }
                      >
                        Your Full Name
                      </FormControlLabel>
                    </div>
                    <div className="form-group-bordered">
                      <input
                        type="tel"
                        id="mobile_no"
                        className="form-control"
                        pattern="[0-9]{10}"
                        onChange={this.handleChange}
                        required
                      />
                      <FormControlLabel
                        className={
                          this.state.mobile_no !== ""
                            ? "control-label active"
                            : "control-label"
                        }
                      >
                        Phone Number
                      </FormControlLabel>
                    </div>
                  </FormGrid>
                  <div className="form-group-bordered">
                    <input
                      id="email"
                      type="email"
                      className="form-control"
                      onChange={this.handleChange}
                      required
                    />
                    <FormControlLabel
                      className={
                        this.state.email !== ""
                          ? "control-label active"
                          : "control-label"
                      }
                    >
                      Email Address
                    </FormControlLabel>
                  </div>
                  <FormGrid>
                    <div className="form-group-bordered">
                      <input
                        id="zipcode"
                        className="form-control"
                        onChange={this.handleChange}
                        required
                      />
                      <FormControlLabel
                        className={
                          this.state.zipcode !== ""
                            ? "control-label active"
                            : "control-label"
                        }
                      >
                        Zip
                      </FormControlLabel>
                    </div>
                    <div className="form-group-bordered">
                      <select
                        id="state"
                        className="form-control"
                        onChange={this.handleChange}
                      >
                        <option value="" selected="selected">
                          State
                        </option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                      </select>
                    </div>
                  </FormGrid>
                  <div className="form-group-bordered">
                    <textarea
                      id="comment"
                      className="form-control"
                      onChange={this.handleChange}
                      required
                    ></textarea>
                    <FormControlLabel
                      className={
                        this.state.comment !== ""
                          ? "control-label active"
                          : "control-label"
                      }
                    >
                      Ask Your Query
                    </FormControlLabel>
                  </div>
                  <div className="form-action">
                    <FormButton text="Submit" />
                  </div>
                </form>
              </FormColumn>
              <BenefitsColumn>
                <h2>{pageData.featuresTitle}</h2>
                {pageData.features.map(item => {
                  return (
                    <BenefitsItem key={v4()}>
                      <CircleIcon>
                        <img src={item.icon} alt={item.title} />
                      </CircleIcon>
                      <BenefitsText>
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                      </BenefitsText>
                    </BenefitsItem>
                  )
                })}
              </BenefitsColumn>
            </RtoGrid>
          </div>
        </Section>
        <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bg="#F6F6F6">
          <div className="container">
            <SectionTitle maxWidth="550px">
              {pageData.followSectionTitle}
            </SectionTitle>
            <SectionDescription mb="40px">
              {pageData.followSectionDescription.followSectionDescription}
            </SectionDescription>
            <PaymentStep data={pageData.followSteps} />
          </div>
        </Section>
        <CallToAction sectionData={pageData.ctaSection} />
        <Section pt="90px" pb="90px" xpt="60px" xpb="30px" bg="#F6F6F6">
          <div className="container">
            <SectionTitle maxWidth="550px">
              {pageData.productSectionTitle}
            </SectionTitle>
            <SectionDescription>
              {pageData.productSectionDescription.productSectionDescription}
            </SectionDescription>
            <RelatedProductCarousel
              data={products}
              showQuote={this.showQuote}
            />
          </div>
        </Section>
        <QuotePopupModal
          isVisible={isVisibleQuote}
          onClose={this.onClosePopup}
          location={location}
        />
      </Layout>
    )
  }
}

export default FinancePage

export const pageQuery = graphql`
  query FinancePageQuery($id: String!) {
    contentfulRtoFinancingPage(id: { eq: $id }) {
      metaTitle
      metaDescription
      title
      url
      secondSectionTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        fluid {
          aspectRatio
          base64
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      formTitle
      featuresTitle
      features {
        title
        description
        icon
      }
      followSectionTitle
      followSectionDescription {
        followSectionDescription
      }
      followSteps {
        title
        description {
          description
        }
        order
        image {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      ctaSection {
        sectionTitle
        subTitle
        sectionButtonText
        sectionButtonLink
        sectionImages {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      productSectionTitle
      productSectionDescription {
        productSectionDescription
      }
      tabsContent {
        tabName
        content {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulProduct {
      edges {
        node {
          id
          productName
          url
          popularLabel
          image {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          width
          height
          length
          totalPrice
          depositPrice
          priceDescription
          featuresImage {
            title
            file {
              url
            }
          }
          productRoof {
            roofName
          }
          productCategory {
            link
          }
        }
      }
    }
  }
`
