import React from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"
import PhoneIcon from '../../components/Icons/PhoneIcon'
import QouteIcon from '../../components/Icons/QouteIcon'
import DefaultButton from "../../components/button/DefaultButton"
import DefaultOutlineButton from "../../components/button/DefaultOutlineButton"
import { BreadCrumb } from "../../components/section"
import ProductRoofType from "../../components/ProductRoofType"
import BreakpointUp from "../../components/Media/BreakpointUp"
import BreakpointDown from "../../components/Media/BreakpointDown"
import generateHTML from '../../utils/generateHTML';
import Content, { HTMLContent } from '../../components/Content';
import QuotePopup from '../../components/QuotePopup'
import QuotePopupModal from '../../components/QuotePopupModal'

const ProductRoofSection = styled.div`
	padding-top: 150px;
	padding-bottom: 40px;
	background:#f6f6f6;
	& h1 {
		font-size:34px;
		color:#020304;
		line-height:40px;
		margin-bottom:15px;
	}
`
const TextSection = styled.div`
	margin:0 -15px 60px -15px;
	${BreakpointUp.lg`        
		display:flex;
		flex-wrap:wrap;
		align-items: center;
		justify-content: space-between;
    `}
	
`
const TextContent = styled.div`
	width:100%;
	padding:0 15px;
	${BreakpointUp.lg`  
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	`}
	
`
const ButtonWrap = styled.div`
	width:100%;
	padding:0 15px;
	${BreakpointUp.lg`
		text-align:right;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	`}
	& a{
		display:inline-block;
		${BreakpointDown.sm`  
			margin:0;
			display:block;
			& .btn{
				display:block;
				width:100%;
				max-width: none !important;
			}
			+ a{
				margin:20px 0 0;
			}
		`}
		${BreakpointUp.lg`  
			margin:0;
			display:block;
			+ a{
				margin:20px 0 0;
			}
		`}
		& .btn{
			padding: 16px 40px;
			font-size: 20px;
			max-width: 280px;
			width: 100%;
		}
	}
	& .quote-btn {
		width: 100%;
		padding: 10px 0;
		& span {
			padding: 8px;
		}
		.btn {
			margin-top: 15px;
			width: 100%;
			max-width: 280px;
			font-size: 20px;
		}
		&:hover {
			cursor: pointer;
		}
		@media(max-width: 990px) {
			.btn {
				margin: 0 15px;
				padding: 12px 0;
			}
		}
		@media(max-width: 580px) {
			.btn {
				margin: 15px 0;
				padding: 12px 0;
				max-width: 100%;
			}
		}
	}
`

class ProductRoofHero extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isVisibleQuote: false,
		}
		this.showQuote = this.showQuote.bind(this)
    this.onClosePopup = this.onClosePopup.bind(this)
	}
	showQuote() {
    this.setState({
      isVisibleQuote: true,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.add('modal-open')
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.remove('modal-open')
    }
  }
	render() {
		const { isVisibleQuote } = this.state
		const { title, description, roofs, location } = this.props
		const PostContent = HTMLContent || Content;
		return(
			<>
				<section>
					<ProductRoofSection>
						<div className="container">
							<BreadCrumb><Link to="/">Home</Link> / <span>{title}</span></BreadCrumb>			
							<TextSection>
								<TextContent>
									<h1>{title}</h1>
									<PostContent
			              content={generateHTML(description.childMarkdownRemark.html)}
			            />
								</TextContent>
								<ButtonWrap>
									<a href="tel:8776005980"><DefaultButton icon={<PhoneIcon />} text="(877) 600-5980" /></a>
									<span class="quote-btn" onClick={this.showQuote}>
										<DefaultOutlineButton icon={<QouteIcon />} text="Request A Quote" />
									</span>
								</ButtonWrap>
							</TextSection>	
						</div>
						<div className="container-fluid">
							<ProductRoofType data={roofs} />				
						</div>
					</ProductRoofSection>
				</section>
				<QuotePopupModal
				  isVisible={isVisibleQuote}
					onClose={this.onClosePopup}
					location={location}
				/>
			</>
		)
	}
}

export default ProductRoofHero