import styled from "styled-components"

export const FormWrap = styled.form`
  //form-group
  .form-group,
  .form-group-sep {
    margin-bottom: 15px;
    @media (min-width: 1200px) {
      margin-bottom: 20px;
    }
  }
  .form-action {
    margin-top: 10px;
  }

  .form-control {
    font-family: "Oxanium", sans-serif;
    display: block;
    width: 100%;
    background: #eef0fd;
    color: #373636;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    border: 1px solid #eef0fd;

    padding: 6px 15px;
    border-radius: 6px;
    @media (min-width: 1200px) {
      padding: 8px 25px;
      border-radius: 8px;
    }
    @media (min-width: 1600px) {
      padding: 10px 25px;
      border-radius: 10px;
    }
    &::placeholder {
      color: #373636;
      font-weight: 500;
    }
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border-color: #373636;
      &::placeholder {
        color: #000;
      }
    }
  }

  .form-action {
    &.btn-block {
      .btn,
      button {
        width: 100%;
      }
    }
  }
  textarea.form-control {
    resize: none;
  }

  //input-icon
  .input-icon {
    position: relative;
    left: 0;
    > .form-control {
      background: transparent;
      border: 0;
      color: #161420;
      display: block;
      width: 100%;
      font-size: 16px;
      transition: all 0.2s ease-in-out;
      outline: none;
      &:focus {
        outline: none;
      }
    }
    > .icon {
      color: #161420;
      font-size: 20px;
      text-align: center;
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 3;
      padding: 0;
      width: 45px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      > svg {
        vertical-align: middle;
      }
    }
  }

  .input-icon.left > .form-control {
    padding-left: 45px;
    padding-right: 15px;
    @media (min-width: 1200px) {
      padding-left: 45px;
      padding-right: 25px;
    }
    @media (min-width: 1600px) {
      padding-left: 45px;
      padding-right: 25px;
    }
  }
  .input-icon.left > .icon {
    left: 0;
  }
  .input-icon.right > .form-control {
    padding-right: 45px;
    padding-left: 15px;
    @media (min-width: 1200px) {
      padding-right: 45px;
      padding-left: 25px;
    }
    @media (min-width: 1600px) {
      padding-right: 45px;
      padding-left: 25px;
    }
  }
  .input-icon.right > .icon {
    right: 0;
  }
`
