import React from "react"
import { Section, SectionTitle, SectionDescription } from "../../components/section"
import ProductCardList from "../../components/TabProductCardList"
import QuotePopup from '../../components/QuotePopup'
import QuotePopupModal from "../../components/QuotePopupModal";

class CategoryLandingProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleQuote: false,
    }
    this.showQuote = this.showQuote.bind(this)
    this.onClosePopup = this.onClosePopup.bind(this)
  }
  showQuote() {
    this.setState({
      isVisibleQuote: true,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.add('modal-open')
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.remove('modal-open')
    }
  }
  render() {
    const { isVisibleQuote } = this.state
    const { title, description, productsData, category, location } = this.props

    return (
      <>
        <Section pt="70px" pb="70px" xpt="60px" xpb="60px">
          <div className="container">
            <SectionTitle maxWidth="550px">{title}</SectionTitle>
            <SectionDescription>{description}</SectionDescription>
            <ProductCardList
              category={category}
              data={productsData}
              limit="all"
              buttonTxt="Explore all Buildings"
              buttonLink="/products"
              showQuote={this.showQuote}
            />
          </div>
        </Section>
        <QuotePopupModal
          isVisible={isVisibleQuote}
          onClose={this.onClosePopup}
          location={location}
        />
      </>
    )
  }
}

export default CategoryLandingProducts